import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from '../../../api/apiEndPoints';
import { getRequest } from 'screens/utils/axiosUtils';
// thunk

export const fetchUserBookings = createAsyncThunk(
  'bookings/fetchUserBookings',
  async (userId, thunkAPI) => {
    const userBookings = await getRequest(`${api().users}/${userId}/bookings`);
    if (userBookings) return userBookings;
    return [];
  }
)

// Then, handle actions in your reducers:
export const userBookingsSlice = createSlice({
  name: 'userBookingsState',
  initialState: {
      userBookings: {
          items: [],
          links: {},
          meta: {}
      },
      loading: 'idle'
  },
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as
    builder
    .addCase(fetchUserBookings.pending, (state) => {
        // Set loading state to 'loading' when the request is in progress
        state.loading = 'loading';
      })
    .addCase(fetchUserBookings.fulfilled, (state, action) => {
      // Add user to the state array
      state.loading = 'idle';
      const { items = [], links = {}, meta = {} } = action.payload;
      state.userBookings = { items, links, meta };
    })
    .addCase(fetchUserBookings.rejected, (state) => {
        // Set loading state back to 'idle' when the request is rejected
        state.loading = 'error';
      })
  },
})

export const userBookingsSelector = (state) => state.userBookings.userBookings;
export const loadingSelector = (state) => state.userBookings.loading;
export default userBookingsSlice.reducer;
