import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/apiEndPoints";
import axios from "axios";
import { toast } from "react-toastify";
import { showSuccessToast } from "screens/utils/toastUtil";

export const authorizeUser = createAsyncThunk(
  "auth/authorizeUser",
  async (body, actions, thunkAPI) => {
    try {
      const res = await axios.post(api().login, body);

          // actions.setSubmitting(false);
          if (res.status === 200) {
              localStorage.setItem('token', res.data.access_token);
              localStorage.setItem('refresh_token', res.data.refresh_token);
              localStorage.setItem('company_id', res.data.company_id);
              localStorage.setItem('role', res.data.role);
              localStorage.setItem('user_id', res.data.user_id);
              localStorage.setItem('user_email', res.data.email);
              localStorage.setItem('phone', res.data.phone_number);
              localStorage.setItem('botStatus', res.data.bot_on_off);
              localStorage.setItem('firstName', res.data.first_name);
              localStorage.setItem('company_language', res.data.company_language);
              localStorage.setItem('language_preference', (res.data.language_preference ?? "").toLowerCase());
          }
          showSuccessToast('Successfully logged in!')
          return res.data;
        } catch (err) {
            console.log(err.response.data.message);
            toast.error("Something went wrong. Please try again!");
            return err.response;
            // actions.setSubmitting(false);
        }
    }
);

export const forgotPassword = createAsyncThunk(
  "forgotPawword",
  async (payload) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(api().forgotPassword, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) showSuccessToast("Email sent successfully!");
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: { auth: {}, loading: "idle" },
  reducers: {
    clearAuthorization: (state) => {
      state.value = false;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as
    builder
      .addCase(authorizeUser.fulfilled, (state, action) => {
        // Set loading state to 'loading' when the request is in progress
        state.auth = action.payload;
        state.loading = "idle";
      })
      .addCase(authorizeUser.pending, (state, action) => {
        // Set loading state to 'loading' when the request is in progress
        state.loading = "loading";
      })
      .addCase(authorizeUser.rejected, (state, action) => {
        // Set loading state to 'loading' when the request is in progress
        state.loading = "error";
      });
  },
});

export const authSelector = (state) => state.userAuth.auth;
export const { clearAuthorization } = authSlice.actions;
export const loadingSelector = (state) => state.userAuth.loading;
export default authSlice.reducer;
